import React, {useState} from 'react'
import './index.scss'
const ServicesCart = ({workList, style, width}) => {
    const [activeParent, setActiveParent] = useState(0)
    const styleScss = {
        backgroundColor:'#fff',
        color:'#eb370d',
        flexDirection:'column',
        width:width + 'px'
    }
    return (
        <div className="cards" style={{flexDirection:style.flexDirection}} >
            {workList.map(card => (
                <div className="child-card" key={card.id}>
                    <div className="service-container">
                        <div className='service-box' style={style} >
                            <img src={card.icon} alt=""/>
                            <span className="title">{card.label}</span>
                            {/* <p className="content">{card.desc}</p> */}
                        </div>
                        {
                            card?.children?.length > 0 && <p style={{cursor:'pointer'}} onClick={
                                () => {
                                    activeParent === card.id ?
                                        setActiveParent(0)  :
                                        setActiveParent(card.id)
                                }
                            }>Ətraflı bax</p>
                        }

                    </div>
                    {
                        activeParent === card.id && card.children && <ServicesCart workList={card.children} width={width - 50} style={styleScss} />
                    }
                </div>
            ))}
        </div>
    )
}

export default ServicesCart

import Adress from '../../components/pages/address'
import Randevu from '../../components/pages/randevu'
import Hero from '../../components/pages/hero'
import Services from '../../components/pages/services'

const Home = () => {
    return (
        <>
            <Hero/>
            <Services/>
            <Randevu/>
            <Adress/>
        </>
    )
}

export default Home

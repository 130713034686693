import React from 'react'
const Address = () => {
    return (
        <div className='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1517.975477157769!2d49.737123001019974!3d40.45422248912547!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4030856d3b0f1bc9%3A0xffb6517a8b3ac135!2zYcSfIGRpxZ8gc3RvbWF0b2xvamkgbcmZcmvJmXo!5e0!3m2!1str!2saz!4v1702621490927!5m2!1str!2saz"
                height="300px"
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" />
        </div>
    )
}

export default Address

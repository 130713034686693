import './doctorCard.scss'
const DoctorCard = ({doctor}) => {
    return (
        <div className='doctor-card'>
            <div className={doctor?.photo ? 'profil-photo' : 'profil-photo null'}>
                { doctor?.photo
                    ?
                    <img src={`https://api-denthouse.medbooking.az/${doctor?.photo}`} alt=""/>
                    :
                    <div className='null-photo'>{doctor?.name?.[0] + '' + doctor?.surname?.[0]}</div>
                }
            </div>
            <div className="doctor-name">{doctor?.name + ' ' + doctor?.surname}</div>
            <div className="role">{doctor?.duty_label}</div>
        </div>
    )
}

export default DoctorCard
